<template>
    <div class="container_con">
        <el-card>
            <div class="new_search_box flex">
                <div style="margin-top:0px">
                    <span class="mr_20">
                        时间：
                        <el-date-picker v-model="queryParams.time" type="daterange" value-format="YYYY-MM-DD"
                            placeholder="请选择时间" range-separator="至" style="width: 300px;" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </span>
                    <span class="mr_20">
                        单号：
                        <el-input v-model="queryParams.sale_out_no" placeholder="请输入单号(多个,分割)" style="width:240px" />
                    </span>
                    <span class="mr_20">
                        客户名称：
                        <el-input v-model="queryParams.customer" placeholder="请输入客户名称(多个,分割)" style="width:240px" />
                    </span>
                    <span class="mr_20">
                        业务员：
                        <el-input v-model="queryParams.admin_name" placeholder="请输入业务员(多个,分割)" style="width:240px" />
                    </span>
                    <el-button type="primary" @click="handleQuery()">搜索</el-button>
                    <el-button @click="resetQuery()">重置</el-button>

                </div>
            </div>
            <div class="search">
                <div>
                    <el-button type="primary" @click="try_sele" v-if="have_auth('/salesOut/add')">选择销售计划单</el-button>
                    <el-button type="primary" icon="el-icon-printer" :disabled="multiple" @click="try_print"
                        v-if="have_auth('/salesOut/print')">打印</el-button>
                    <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/salesOut/export')"
                        :loading="is_export" @click="try_export">导出</el-button>
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column prop="sale_out_date" label="开票时间" />
                    <el-table-column prop="sale_out_no" label="单据编号" />
                    <el-table-column prop="admin_name" label="开票人" />
                    <el-table-column prop="merchant_no" label="单位编号" />
                    <el-table-column prop="merchant_name" label="销往单位" />
                    <el-table-column prop="amount" label="整单金额" sortable :sort-method="formatter" />
                    <el-table-column prop="gross_profit_price" label="整单毛利" sortable :sort-method="formattergross_profit_price"/>
                    <el-table-column fixed="right" label="操作" width="150">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleDetail(scope.row)"
                                v-if="have_auth('/salesOut/view')">查看</el-button>
                            <el-button type="text" size="small" @click="handleHid(scope.row, 1)"
                                v-if="$store.state.userInfo.is_admin == 1 && scope.row.is_hid != 1">隐藏</el-button>
                            <el-button type="text" size="small" @click="handleHid(scope.row, 2)"
                                v-if="$store.state.userInfo.is_admin == 1 && scope.row.is_hid == 1">恢复</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
        @pagination="getList" />
    <selectsalesPlan ref="selectsalesPlan" @after_sele="after_selectsalesPlan" />
    <print ref="print" />
</template>

<script>
import selectsalesPlan from "./selectsalesPlan";
// 打印
import print from "@/components/print";
export default {
    name: "department",
    components: { selectsalesPlan, print },
    data() {
        return {
            // 列表模板
            loading: false,
            // 查询参数
            queryParams: {
                page: 1,
                size: 10,
                sale_out_no: '',
                customer: '',
                admin_name: '',
                time: ''
            },
            total: 0,
            list: [],

            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            is_export: false,
        };
    },
    created() {
        this.queryParams = window[this.$route.path] || {
            page: 1,
            size: 10,
            sale_out_no: '',
            customer: '',
            admin_name: '',
            time: ''
        }
        this.getList();
    },
    watch: {
        $route(to, form) {
            window[form.path] = this.queryParams
        },
    },
    methods: {
        formatter(a, b) {
            return Number(a.amount) - Number(b.amount);
        },
        formattergross_profit_price(a, b) {
            return Number(a.gross_profit_price) - Number(b.gross_profit_price);
        },
        /** 按钮操作 */
        handleHid(row, type) {
            const _this = this;
            const ids = row.id || this.ids;
            this.$confirm('是否确认' + (type == 1 ? '隐藏' : '恢复') + '?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                _this.$httpGet("/backend/SaleOut/hide", { id: ids, type }).then((res) => {
                    if (res.status == 200) {
                        _this.getList();
                        _this.$message.success('操作成功');
                    } else {
                        _this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            })
        },
        resetQuery() {
            this.queryParams = {
                page: 1,
                size: 10,
                sale_out_no: '',
                customer: '',
                admin_name: '',
                time: ''
            }
            this.getList()
        },
        try_export() {
            if (this.is_export) { return false; }
            this.is_export = true;
            let _data = { ...this.queryParams };
            _data.startTime = _data.time ? _data.time[0] : '';
            _data.endTime = _data.time ? _data.time[1] : '';
            this.$httpGet("/backend/SaleOut/export", _data).then((res) => {
                if (res.status == 200) {
                    var a = document.createElement('a');
                    let name = res.data.file.split('/')
                    var fileName = name[name.length - 1];
                    a.download = fileName;
                    a.href = this.$http + '/' + res.data.file;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    this.$message.error(res.message);
                }
                this.is_export = false;
            }).catch((err) => {
                console.log(err);
                this.is_export = false;
            });
        },
        try_print() {
            this.$refs.print.init(this.ids, 4)
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 查看详情
        handleDetail(row) {
            this.$router.push('/planOutDetails?id=' + row.id)
        },
        after_selectsalesPlan(arr) {
            this.$router.push('/addsalesOut?id=' + arr[0].id + '&ids=' + arr.map(item => item.id).join(','))
        },
        try_sele() {
            this.$refs['selectsalesPlan'].init()
        },

        /** 查询列表 */
        getList() {
            this.loading = true;
            let _data = { ...this.queryParams };
            _data.startTime = _data.time ? _data.time[0] : '';
            _data.endTime = _data.time ? _data.time[1] : '';
            this.$httpGet("/backend/SaleOut/index", _data).then((res) => {
                if (res.status == 200) {
                    this.loading = false;
                    this.list = res.data.data;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped></style>
