<template>
  <div>
    <el-dialog v-model="dialogVisible" width="1100px" append-to-body :show-close="false">
      <template #title>
        <div class="dialog_box">
          <div class="con_title"><span></span> 选择销售计划单</div>
          <div>
            <el-select v-model="queryParams.sale_plan_no" placeholder="请输入客户名称/助记码/客户编号" clearable remote :remote-method="remoteMethod"
                style="width:240px; margin-right: 10px;" filterable size="mini">
                <el-option :label="item.merchant_name" v-for="(item,index) in hwlist"
                    :key="index" :value="item.id"></el-option>
            </el-select>
            <el-button size="mini" type="primary" @click="handleQuery()">搜索</el-button>
          </div>
        </div>
      </template>
      <el-table
        :data="list"  v-loading="loading"  
        style="width: 100%" ref="tableDataRefs"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :selectable="selectable_func" />
        <el-table-column prop="sale_plan_date" label="开票时间" />
        <el-table-column prop="sale_plan_no" label="单据编号" />
        <el-table-column prop="department_name" label="部门" />
        <el-table-column prop="admin_name" label="开票人" />
        <el-table-column prop="merchant_name" label="销往单位" />
        <el-table-column prop="merchant_no" label="单位编号" />
        <!-- <el-table-column prop="merchant_no" label="产品编号" /> -->
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        v-model:page="queryParams.page"
        v-model:limit="queryParams.size"
        @pagination="getList"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="multiple" @click="confirm_sele">确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'selectpurchasePlan',
  props: {
    
  },
  data() {
    return {
        // 列表模板
        loading: false,
        // 查询参数
        queryParams: {
            page: 1,
            size: 10,
            sale_plan_no: ''
        },
        total:0,
        list:[],
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        hwlist:[],
        dialogVisible:false,
        disabled_ids:[],  // 上级页面已选择的，这里不能再选
    }
  },
  
  methods: {
      remoteMethod(keyword){
          this.$httpGet("/backend/Merchant/merchant_sale", {size:1000000,title:keyword}).then((res) => {
              if (res.status == 200) {
                  this.hwlist = res.data.data;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
    init(ids){
        this.dialogVisible=true;
        this.queryParams={
            page: 1,
            size: 10,
            product_no: ''
        }
        this.disabled_ids=ids || [];
        this.getList();
        this.remoteMethod()
    },
    confirm_sele(){
        let arr=[];
        for(let i=0;i<this.list.length;i++){
            if(this.ids.indexOf(this.list[i].id)!=-1){
                arr.push(this.list[i])
            }
        }
        this.$emit('after_sele',arr);
        this.dialogVisible=false;
    },
    // 已选的id禁选
    selectable_func(row,index){
        if(this.disabled_ids.indexOf(row.id)!=-1){
            return false
        }
        return true
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = this.ids.length != 1;
      this.multiple = !this.ids.length;
    },
    /** 查询列表 */
    getList() {
        this.loading = true;
        this.$httpGet("/backend/SalePlan/plan_index", this.queryParams).then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.list = res.data.data;
                this.total = res.data.total;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
        this.queryParams.page = 1;
        this.getList();
    }
  }
}
</script>

<style scoped>
.dialog_box {
  display: flex;
  justify-content: space-between;
}
</style>
